import React from "react"
import Lottie from "react-lottie"
import Layout from "../components/layout"
import Header from "../components/header"
import Footer from "../components/footer"
import LaunchButton from "../components/launch-button"
import Seo from "../components/seo"
import OrbitData from '../lotties/Orbit'
import ChallengesData from '../lotties/Challenges'
import GroupsData from '../lotties/Groups'
import ImpactData from '../lotties/Impact'
import PhoneOrbitData from '../lotties/PhoneOrbit.json'
import UICardsData from '../lotties/UICards.json'
import DiagramVertData from '../lotties/DiagramVertical'
import DiagramWideData from '../lotties/DiagramWide'
import cardHeader from '../images/card-header.svg'

export default class IndexPage extends React.Component {

  render() {

    function defaultOptions(animationData) {
      return {
        loop: true,
        autoplay: true,
        animationData: animationData,
      }
    }   

    return <Layout hero={true}>

      <Seo 
        title="Tideal – Climate smart living for people who care" 
        siteUrl="www.tideal.app"
      />

      <section className="heroSection">
        <Header lang="En" />
        <div className="widthContainer">
          <h1 className="sectionHeader">Climate smart living for people who <span>care</span>.</h1>
          <p>Tideal is a community led and data-inspired climate action platform. It's built on lifestyle change challenges which help you and your friends turn the way you live into more climate-friendly.</p>
          <div className="lottieWrapper">
            <Lottie options={defaultOptions(OrbitData)} height={1350} width={1350} isClickToPauseDisabled={true} />
          </div>
        </div>
      </section>

      <section className="diagramSection">
        <div className="widthContainer">
          <h2 className="sectionHeader">Cut your impact on the climate, together with friends.</h2>
          <div className="horizontalDiagram">
            <Lottie options={defaultOptions(DiagramWideData)} width={850} height={150} isClickToPauseDisabled={true} />
          </div>
          <div className="subSections">
            <div className="subSection">
              <h3 className="subSectionHeader">Challenges</h3>
              <p>Challenges are themed around bite-sized sections of human activity. They are based on solid data: for every challenge, we've researched the carbon footprints of the most common individual choices.</p>
              <Lottie options={defaultOptions(ChallengesData)} width={204} height={140} isClickToPauseDisabled={true} />
            </div>
            <div className="subSection">
              <h3 className="subSectionHeader">Groups</h3>
              <p>Anyone can set up a new challenge group and invite friends and family to join it. When you join a group, we ask you a couple of questions, calculate your current footprint, and set up a reduction goal for you.</p>
              <Lottie options={defaultOptions(GroupsData)} width={204} height={140} isClickToPauseDisabled={true} />
            </div>
            <div className="subSection">
              <h3 className="subSectionHeader">Impact</h3>
              <p>What happens as the group starts changing their habits for the better is impact! We show this impact on the individual, group, and platform levels in terms of reduction to the green house gases emitted.</p>
              <Lottie options={defaultOptions(ImpactData)} width={204} height={140} isClickToPauseDisabled={true} />
            </div>
          </div>
          <div className="verticalDiagram">
            <Lottie options={defaultOptions(DiagramVertData)} width={254} height={731} isClickToPauseDisabled={true} />
          </div>
        </div>
      </section>

      <section className="challengeSection">
        <div className="widthContainer">
          <h2 className="sectionHeader">Lifestyle challenges from all areas of life - well, from two to start off with.</h2>
          <p>Old habits die hard. For most of us, the most likely successful path to changing our lives to be less taxing for the climate is to take it step by step, changing one thing at a time. That's why Tideal has challenges, designed to be taken one by one.</p>
          {/*<button className="launchButton"><span>Launch Tideal <img src={logoIcon} alt="Tideal-logo" /></span></button>*/}
          <p>We are launching with just a few challenges, but aim to deliver you more of them in the future. What would you like to see on our challenge list? Drop us a line: <a href="mailto:info@tideal.app">info@tideal.app</a></p>
          <div className="phoneOrbitWrapper"> 
            <Lottie options={defaultOptions(PhoneOrbitData)} width={559} height={507} isClickToPauseDisabled={true}/>
          </div>
        </div>
      </section>

      <section className="groupsSection">
        <div className="widthContainer">
          <h2 className="sectionHeader">Tideal is better with friends - and impact is bigger in groups.</h2>
          <p>Tideal challenges are tackled in groups. Cheer on each other's successes, share practical tips on how you're changing your behaviour, or even compete a little and see who can achieve the smallest footprint. Together, your impact will be bigger.</p>
          <p>If you set up a new group and become its captain, you decide if the group is by invitation only (good for friends, family, and inside jokes) or open to anyone.</p>
          <div className="UICardsWrapper">
            <Lottie options={defaultOptions(UICardsData)} width={515} height={494} isClickToPauseDisabled={true} />
          </div>
        </div>
      </section>

      <section className="cardSection">
        <div className="widthContainer">
          <h2 className="sectionHeader" style={{ backgroundImage: "src("+{cardHeader}+") cover"}}>Made for everyone, tailored for schools and companies.</h2>
          <div className="col-3">
            <div className="card">
              <h3 className="subSectionHeader">For you</h3>
              <p>Join an existing group, or set up your own and invite friends and family into it.</p>
              <hr />
              <p>Achieve bigger emissions reduction together.</p>
              <hr />
              <p>Always free, no ads, no gimmicks.</p>
              <hr />
              <p>
                {/*
                Inquiries by email:<br />
                <a href="mailto:info@tideal.app">info@tideal.app</a><br />
                */}
                <LaunchButton />
              </p>
            </div>
          </div>
          <div className="col-3">
            <div className="card">
              <h3 className="subSectionHeader">For education</h3>
              <p>By-invitation-only groups to the students and staff of your school.</p><hr />
              <p>See school-level impact.</p><hr />
              <p>Climate education on a consumer grade app (like your students expect).</p><hr />
              <p>Help students develop climate literacy in a safe online environment.</p><hr />
              <p>
                Inquiries by email:<br />
                <a href="mailto:info@tideal.app">info@tideal.app</a><br />
                <button className="launchButton only-text" disabled>Beta in 2021</button>
              </p>
            </div>
          </div>
          <div className="col-3">
            <div className="card">
              <h3 className="subSectionHeader">For companies</h3>
              <p>By-invitation-only groups to the employees of your company.</p><hr />
              <p>See company-level impact.</p><hr />
              <p>Reduce the carbon footprint of everyday company life.</p><hr />
              <p>Demonstrate your company's commitment to sustainability.</p><hr />
              <p>
                Inquiries by email:<br />
                <a href="mailto:info@tideal.app">info@tideal.app</a><br />
                <button className="launchButton only-text" disabled>Beta in 2021</button>
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </Layout>
  }
}
